import { sendLog } from "../../exports/debug";
import { transition, transitions } from "./transition";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function fadeOut<T extends HTMLElement, F extends (...args: never[]) => void>(
  target: T,
  callback?: F,
): void {
  const { stack } = Error("");
  sendLog("assets-animation", {
    message: "fadeOut has been called",
    stack,
    caller: stack?.split("\n")[1],
  });

  if (window.getComputedStyle(target).opacity !== "0") {
    transition(target, transitions.fadeOut, callback);
  } else if (typeof callback === "function") {
    callback();
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function fadeIn<T extends HTMLElement, F extends (...args: never[]) => void>(
  target: T,
  callback?: F,
): void {
  const { stack } = Error("");
  sendLog("assets-animation", {
    message: "fadeOut has been called",
    stack,
    caller: stack?.split("\n")[1],
  });

  if (window.getComputedStyle(target).opacity !== "1") {
    transition(target, transitions.fadeIn, callback);
  } else if (typeof callback === "function") {
    callback();
  }
}
