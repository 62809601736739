import { noop as _ } from "./function.js";
const o = window, t = /*           */ new Proxy(_, {
  get: () => t
});
o.AS || (o.AS = {}), o.o_global || (o.o_global = {}), o.o_util || (o.o_util = {});
o.__toggles__ || (o.__toggles__ = {});
const { AS: e, o_global: g, o_util: s, __toggles__: a } = o;
export {
  e as AS,
  a as __toggles__,
  t as autoNamespace,
  g as o_global,
  s as o_util,
  o as window
};
