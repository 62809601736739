import { o_util } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import { getNewScrollPosition } from "../animation/utils.js";
import { ajaxLegacy } from "./ajax/ajax.legacy.js";

import { ajax } from "./ajax/index.js";
import "./helper.js";

interface LegacyUtils {
  legacy: {
    ajaxLegacy: typeof ajaxLegacy;
    getNewScrollPosition: typeof getNewScrollPosition;
  };

  ajax: typeof ajax;
}

const oLegacy = o_util as unknown as LegacyUtils;

assignNamespace(oLegacy, "legacy", {
  ajaxLegacy,
  getNewScrollPosition,
});

oLegacy.ajax = ajax;
