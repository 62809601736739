import { o_util } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import { getConnectionType } from "./utils";

/**
 *
 *
 */
export function isNormalNavigation(): boolean {
  /*                                                                                     */
  return getConnectionType() === 0;
}

/**
 *
 *
 */
export function isReloadNavigation(): boolean {
  /*                                                                                     */
  return getConnectionType() === 1;
}

/**
 *
 *
 */
export function isForwardBackwardNavigation(): boolean {
  /*                                                                                     */
  return getConnectionType() === 2;
}

export { getConnectionType } from "./utils";

assignNamespace(o_util, "connection", {
  isNormalNavigation,
  isReloadNavigation,
  isForwardBackwardNavigation,
  getConnectionType,
});
