import { __toggles__, o_global } from "@gr-common/head/namespaces";
import { get as getCookie } from "../cookie/get.js";
import { COOKIE_PREFIX, normalize, normalizeLegacy } from "./utils.js";

/**
 *
 */
export type ToggleValue = boolean | number;

/**
 *
 *
 *
 *
 *
 */
export type UserToggle =
  | [value: ToggleValue, allowOverride?: ToggleValue]
  | ToggleValue
  | undefined;

/**
 *
 */
const cookieMap = { true: 1, false: 0, "1": 1, "0": 0 };

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function get(toggleName: string, defaultValue = false): boolean {
  const rawToggle = __toggles__[toggleName];
  const rawLegacy = o_global.toggles?.[toggleName];

  /*                                     */
  /*                                                              */
  const toggle = rawToggle
    ? normalize(rawToggle, defaultValue)
    : normalizeLegacy(rawLegacy, defaultValue);

  if (!toggle[1]) {
    /*                   */
    return toggle[0];
  }

  /*                             */
  const localStatus = getCookie(COOKIE_PREFIX + toggleName) as keyof typeof cookieMap | undefined;

  /*                         */
  if (!localStatus || cookieMap[localStatus] === undefined) {
    /*                      */
    return toggle[0];
  }

  /*                    */
  return !!cookieMap[localStatus];
}
