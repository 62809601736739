/*                                             */
import {
  AjaxCallback,
  AjaxCallbackOrUndefined,
  AjaxGetScriptOptions,
  AjaxInterface,
  AjaxOptions,
  AjaxRequestOrPromise,
  XMLHttpRequestProcessed,
} from "./types";
import {
  getCustomTimeStamp,
  injectScript,
  sanitizeGetScriptOptions,
  sanitizePostOptions,
  validateOptions,
} from "./utils";
import { sendRequest } from "./ajax.send";

import { extend } from "../../../exports/core.js";
import { logger } from "../../../exports/debug";

const log = logger("o_util.ajax");

/*                     */
function ajaxBase<Callback extends AjaxCallbackOrUndefined = undefined>(
  options: AjaxOptions,
): AjaxRequestOrPromise<Callback>;
function ajaxBase<Callback extends AjaxCallbackOrUndefined = undefined>(
  options: AjaxOptions,
  callback?: Callback,
): AjaxRequestOrPromise<Callback>;
function ajaxBase<Callback extends AjaxCallbackOrUndefined = undefined>(
  options: AjaxOptions,
  callback?: Callback,
): AjaxRequestOrPromise<Callback> {
  /*                     */
  /*                                                     */
  log.warn("o_util.ajax is deprecated use native fetch instead");

  const optionsError = validateOptions(options);

  if (typeof callback === "function") {
    /*                                                             */
    if (optionsError instanceof Error) {
      throw optionsError;
    }

    return sendRequest(
      options,
      callback as AjaxCallback,
      /*                                                                                     */
      ajax,
    ) as AjaxRequestOrPromise<Callback>;
  }

  /*                                                              */
  return new Promise<XMLHttpRequestProcessed>((resolve, reject) => {
    if (optionsError instanceof Error) {
      reject(optionsError);
      return;
    }

    /*                                                                                     */
    sendRequest(options, resolve, ajax);
  }) as AjaxRequestOrPromise<Callback>;
}

/*           */
function get<Callback extends AjaxCallbackOrUndefined = undefined>(
  url: string,
): AjaxRequestOrPromise<Callback>;
function get<Callback extends AjaxCallbackOrUndefined = undefined>(
  url: string,
  callback?: Callback,
): AjaxRequestOrPromise<Callback>;
function get<Callback extends AjaxCallbackOrUndefined = undefined>(
  url: string,
  callback?: Callback,
): Promise<XMLHttpRequestProcessed> | XMLHttpRequest {
  return ajaxBase<Callback>({ method: "GET", url }, callback);
}

/**
 *
 */
function getJSON(url: string): Promise<XMLHttpRequestProcessed>;
function getJSON<Callback extends AjaxCallbackOrUndefined = undefined>(
  url: string,
  callback?: Callback,
): Promise<XMLHttpRequestProcessed> | XMLHttpRequest;
function getJSON<Callback extends AjaxCallbackOrUndefined = undefined>(
  url: string,
  callback?: Callback,
): Promise<XMLHttpRequestProcessed> | XMLHttpRequest {
  return ajaxBase<Callback>({ method: "GET", url, responseType: "json" }, callback);
}

/*            */
function post(
  url: string,
  data: string | Record<string, unknown>,
): Promise<XMLHttpRequestProcessed>;
function post<Callback extends AjaxCallbackOrUndefined = undefined>(
  url: string,
  data: string | Record<string, unknown>,
  callback?: Callback,
): Promise<XMLHttpRequestProcessed> | XMLHttpRequest;
function post<Callback extends AjaxCallbackOrUndefined = undefined>(
  url: string,
  data: string | Record<string, unknown>,
  callback?: Callback,
): Promise<XMLHttpRequestProcessed> | XMLHttpRequest {
  return ajaxBase<Callback>(
    {
      method: "POST",
      url,
      ...sanitizePostOptions(data),
    },
    callback,
  );
}

/*             */
function abort(xhr: XMLHttpRequest): void {
  xhr.abort();
}

/*                 */
function getScript(
  url: string,
  options: boolean | AjaxGetScriptOptions,
): Promise<XMLHttpRequestProcessed>;
function getScript<Callback extends AjaxCallbackOrUndefined = undefined>(
  url: string,
  options: boolean | AjaxGetScriptOptions,
  callback?: Callback,
): Promise<XMLHttpRequestProcessed> | XMLHttpRequest;
function getScript<Callback extends AjaxCallbackOrUndefined = undefined>(
  url: string,
  options?: boolean | AjaxGetScriptOptions,
  callback?: Callback,
): Promise<XMLHttpRequestProcessed> | XMLHttpRequest {
  const opts = sanitizeGetScriptOptions(url, options);

  return typeof callback === "function"
    ? ajaxBase<AjaxCallback>({ method: "GET", url: opts.url }, (xhr) =>
        injectScript<Callback>(xhr, opts.executeImmediately, callback),
      )
    : (
        ajaxBase<undefined>({
          method: "GET",
          url: opts.url,
        }) as Promise<XMLHttpRequestProcessed>
      ).then((xhr) => injectScript(xhr, opts.executeImmediately));
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export const ajax: AjaxInterface = extend(
  ajaxBase,
  {
    get,
    getJSON,
    getScript,
    post,
    abort,
    active: 0,
    _getCustomTimeStamp: getCustomTimeStamp,
  },
  false,
  false,
);

export * from "./types";
