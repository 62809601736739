import { dateNow } from "../utils/date.js";
import { SAMESITE_VALUES, SamesiteValues } from "./utils";
import { get as toggleGet } from "../toggle/toggle.js";

/**
 *
 */
export interface SetCookieOptions {
  /*                                          */
  minutes?: number;
  /*                                      */
  days?: number;
  /*                                              */
  path?: string;
  /*                                                   */
  domain?: string;
  /*                                                               */
  samesite?: SamesiteValues;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function set<T extends string>(name: string, value: T, options?: SetCookieOptions): void {
  let timeInMinutes = 60;
  const date = dateNow();
  const opts = options || {};
  let cookieString;

  if (opts.minutes) {
    timeInMinutes = opts.minutes;
  }

  if (opts.days) {
    if (!opts.minutes) {
      timeInMinutes = 0;
    }

    timeInMinutes += opts.days * 24 * 60;
  }

  date.setTime(date.getTime() + timeInMinutes * 60 * 1000);

  cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  cookieString += `; expires=${date.toUTCString()}`;
  cookieString += `; path=${opts.path || "/"}`;

  if (opts.domain) {
    cookieString += `; domain=${opts.domain}`;
  }

  if (opts.samesite && SAMESITE_VALUES.includes(opts.samesite)) {
    cookieString += `; SameSite=${opts.samesite}`;

    if (opts.samesite === "None") {
      cookieString += `; Secure`;
    }
  }

  document.cookie = cookieString;

  /*                                      */
  if (toggleGet("LHAS_2048_TRACK_COOKIES", false)) {
    otto.debug.sendLog("o_util.cookie.set()", {
      cookiePath: options && options.path ? options.path : "/",
      cookieExpires: date.toUTCString(),
      cookieDomain: options && options.domain ? options.domain : -1,
      cookieName: name,
      cookieValue: value,
      cookieSameSite: options && options.samesite ? options.samesite : -1,
    });
  }
}
