/*                                             */
/*                                                                */
export type Serializable = Record<string, string | number | boolean>;

/**
 *
 *
 *
 *
 */
export function coerce<T>(val: number | string | T): number | boolean | T {
  /*                                             */
  if (!isNaN(val as number)) {
    return Number(val);
  }

  if (val === "true") {
    return true;
  }

  if (val === "false") {
    return false;
  }

  return val as T;
}

/**
 *
 *
 *
 *
 */
export function serialize<T extends Serializable>(obj: T): string | void {
  if (typeof obj === "object") {
    return Object.keys(obj)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join("&")
      .replace(/%20/g, "+");
  }

  return undefined;
}

/**
 *
 *
 *
 *
 */
export function serializeForm(form: HTMLFormElement): string {
  const isSelect = (
    element: HTMLInputElement | HTMLSelectElement,
  ): element is HTMLSelectElement => {
    return element.type === "select-multiple";
  };

  const isValid = (
    element: HTMLElement & {
      type?: string;
      name?: string;
      disabled?: boolean;
    },
  ): element is HTMLInputElement => {
    return (
      !!element.name &&
      !element.disabled &&
      element.type !== "submit" &&
      element.type !== "reset" &&
      element.type !== "button" &&
      element.type !== "file"
    );
  };

  const isDefault = (element: HTMLInputElement): boolean => {
    return (element.type !== "radio" && element.type !== "checkbox") || element.checked;
  };

  const combine = (k: string, v: string): string =>
    `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;

  const data: string[] = [];
  if (typeof form === "object" && form.nodeName === "FORM") {
    [].forEach.call(form.elements, (element: HTMLElement) => {
      if (isValid(element)) {
        if (isSelect(element)) {
          [].forEach.call(element.options, (option: HTMLOptionElement) => {
            if (option.selected) {
              data[data.length] = combine(element.name, option.value);
            }
          });
        } else if (isDefault(element)) {
          data[data.length] = combine(element.name, element.value);
        }
      }
    });
  }

  return data.join("&").replace(/%20/g, "+");
}

/**
 *
 *
 *
 *
 *
 */
export function deserialize<T extends Serializable>(value: string, coercion = true): T {
  const decoded = decodeURIComponent(value.replace(/\+/g, "%20"));
  const chunks = decoded.split("&");

  const params = new Map();
  chunks.forEach((chunk) => {
    const splittedChunk = chunk.split("=");
    const key = splittedChunk[0];
    const val = coercion ? coerce(splittedChunk[1]) : splittedChunk[1];

    if (key !== "") {
      params.set(key, val);
    }
  });

  return Object.fromEntries(params.entries());
}
