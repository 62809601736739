/**
 *
 *
 *
 *
 */

export type { Serializable } from "../lib/core/index.js";
export const { core } = window.o_util ?? {};
export const {
  bind,
  clone,
  coerce,
  convertStringToFunction,
  deserialize,
  extend,
  isEmptyObject,
  isPlainObject,
  removeFromArray,
  serialize,
  serializeForm,
  toArray,
} = core ?? {};
