/**
 *
 *
 *
 *
 */

export type { SamesiteValues, SetCookieOptions, RemoveCookieOptions } from "../lib/cookie/index.js";

export const { cookie } = window.o_util ?? {};
export const { exists, get, remove, set } = cookie ?? {};
