/*                                                   */

import { arrayRemove, loggerScope } from "./helpers.js";

const log = loggerScope.scope("topic");

/**
 *
 *
 *
 *
 */
export type EventData<D extends unknown[] = unknown[]> = D;

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export type EventCallback<D extends unknown[] = unknown[]> = (
  this: QBusTopic,
  ...data: EventData<D>
) => unknown | undefined | Promise<unknown | undefined>;

/**
 *
 *
 *
 *
 */
interface EventListener<D extends unknown[] = unknown[]> {
  /**
 *
 *
 */
  singleRun: boolean;
  /**
 *
 *
 */
  callback: EventCallback<D>;
}

/**
 *
 *
 */
export class QBusTopic {
  /**
 *
 */
  public readonly topicName: string;

  /**
 *
 */
  public readonly listeners: EventListener<any>[] = [];

  /**
 *
 *
 */
  public readonly dataQueue: EventData[] = [];

  /**
 *
 *
 */
  public retained = false;

  /**
 *
 *
 */
  public retainedMessage?: EventData;

  /**
 *
 *
 *
 *
 */
  public constructor(name: string) {
    this.topicName = name;
  }

  /**
 *
 *
 *
 *
 *
 */
  public queueData<D extends unknown[]>(
    eventData: EventData<D> = [] as unknown as EventData<D>,
  ): EventData<D> {
    this.dataQueue.push(eventData);
    return eventData;
  }

  /**
 *
 *
 */
  public clearQueue(): void {
    this.dataQueue.splice(0);
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  public addListener<D extends unknown[] = unknown[]>(
    callback: EventCallback<D>,
    singleRun = false,
  ): EventListener<D> {
    /*                 */
    const listener = { singleRun, callback };

    /*                 */
    if (
      !this.listeners.some(
        (item) => listener.singleRun === item.singleRun && listener.callback === item.callback,
      )
    ) {
      log.info(this.topicName, "add new listener", callback.name);
      this.listeners.push(listener);
    }

    return listener;
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 */
  public removeByCallback<D extends unknown[] = unknown[]>(callback: EventCallback<D>): boolean {
    let res = false;

    if (callback) {
      this.listeners
        .filter((l) => l.callback === callback)
        .forEach((listener) => {
          this.removeListener(listener);
          res = true;
        });
    }

    return res;
  }

  /**
 *
 *
 *
 *
 *
 *
 */
  public emitAllListener<D extends unknown[]>(
    eventData: EventData<D> = [] as unknown as EventData<D>,
  ): Promise<any[]> {
    return Promise.all(
      [...this.listeners].map((listener) => {
        return this.emitListener(listener, eventData);
      }),
    );
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  public emitListenerWithQueuedData<D extends unknown[] = unknown[]>(
    listener: EventListener<D>,
  ): Promise<(unknown | undefined)[]> {
    /*                                                          */
    /*                                                                    */

    if (this.dataQueue.length > 0) {
      /*                                                                             */
      /*                                                                               */
      if (listener.singleRun) {
        return Promise.all([this.emitListener(listener, this.dataQueue[0] as D)]);
      }

      return Promise.all(
        this.dataQueue.map((eventData) => {
          return this.emitListener(listener, eventData as D);
        }),
      );
    }

    return Promise.resolve([]);
  }

  /**
 *
 *
 *
 *
 *
 *
 */
  public emitListener<D extends unknown[] = unknown[]>(
    listener: EventListener<D>,
    eventData: EventData<D>,
  ): Promise<unknown | undefined> {
    /*                                                                 */
    if (listener.singleRun) {
      this.removeListener(listener);
    }

    /*                                                   */
    log.debug(this.topicName, "dispatch %s", listener.callback.name);
    return Promise.resolve()
      .then(() => listener.callback.call(this, ...(eventData as any)))
      .catch((e: Error) => {
        log.warn(this.topicName, "listener errored with:", e.stack || e.message || e);
        throw e;
      });
  }

  /**
 *
 *
 *
 *
 *
 */
  private removeListener<D extends unknown[] = unknown[]>(listener: EventListener<D>): boolean {
    const removed = arrayRemove(this.listeners, listener);

    if (removed) {
      log.debug(this.topicName, "removed listener", removed.callback.name);
      return true;
    }

    return false;
  }
}
