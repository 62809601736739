import { window } from "@gr-common/head/namespaces";
import type { OttoNexus } from "../lib/nexus/index.js";
import { createProxy } from "../lib/nexus/proxy/proxy.js";
import { configAsync, name } from "../lib/nexus/proxy/proxyConfig.js";
import { createStore } from "../lib/nexus/store/store.js";

export * from "../lib/nexus/index.js";

export type * from "../lib/nexus/namespace/namespace.types.js";
export type * from "../lib/nexus/event/event.types.js";
export type * from "../lib/nexus/function/function.types.js";
export type * from "../lib/nexus/proxy/proxy.types.js";

export * from "../lib/nexus/function/public.js";
export * from "../lib/nexus/event/public.js";
export * from "../lib/nexus/namespace/public.js";

/**
 *
 */
/*                                            */
export const otto: OttoNexus = import.meta.env.SSR
  ? (createProxy(createStore(name)[1], configAsync) as never)
  : (window.otto ?? {});
