import { o_util } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import {
  type Serializable,
  extend as coreExtend,
  serialize as coreSerialize,
  deserialize as coreDeserialize,
} from "../core";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function serialize<T extends Serializable>(obj?: T): string | undefined {
  if (obj && typeof obj === "object") {
    return `#${coreSerialize(obj)}`;
  }

  return undefined;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function deserialize<T extends Serializable>(coerce?: boolean): T;
/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function deserialize<T extends Serializable>(fragment: string, coerce?: boolean): T;
export function deserialize<T extends Serializable>(...args: (string | boolean | undefined)[]): T {
  const fragment = args[0];
  let coerce = args[1];

  /*                                                       */
  /*                                                        */
  if (typeof fragment === "boolean" && typeof coerce === "undefined") {
    coerce = fragment;
  } else {
    /*                                                */
    coerce = typeof coerce === "boolean" ? coerce : true;
  }

  if (typeof fragment === "string") {
    if (fragment.indexOf("#") > -1) {
      return coreDeserialize<T>(fragment.split("#")[1], coerce);
    }
    return {} as T;
  }
  if (!window.location.hash) {
    return {} as T;
  }
  return coreDeserialize(window.location.hash.substring(1), coerce);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function set(fragment: string): void {
  if (typeof fragment === "string") {
    window.location.hash = fragment;
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function get(param: string, url?: string): string | undefined {
  if (typeof param === "string") {
    const deserialized: Record<string, string> =
      !!url && url.indexOf("#") > -1
        ? deserialize(`#${url.split("#")[1]}`)
        : deserialize(window.location.hash);

    return Object.prototype.hasOwnProperty.call(deserialized, param)
      ? deserialized[param]
      : undefined;
  }

  if (!param) {
    /*                                */
    return window.location.hash === "#" ? /*                */ "" : window.location.hash;
  }

  return undefined;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function remove(param: string, url?: string): boolean | string {
  if (typeof param === "string" && !!get(param, url)) {
    let deserialized: Record<string, string>;

    if (typeof url === "string") {
      if (url.indexOf("#") > 1) {
        const splitted = url.split("#");

        deserialized = deserialize(`#${splitted[1]}`);
        delete deserialized[param];
        const serialized = serialize(deserialized) as string;

        /*                  */
        return splitted[0] + (serialized.length > 1 ? serialized : "");
      }
      return false;
    }

    deserialized = deserialize(window.location.hash);
    delete deserialized[param];

    /*                                   */
    set(serialize(deserialized) as string);

    return true;
  }

  return false;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function push<T extends Serializable>(
  fragment: string | T,
  url?: string,
): string | undefined {
  /*                                                     */
  const fragm = typeof fragment === "object" ? serialize(fragment) : fragment;

  if (typeof fragm !== "string") {
    return undefined;
  }

  /*                                              */
  const target = coreDeserialize(fragm.replace("#", ""), false);
  /*                                                         */
  let source;
  /*                   */
  let merged;
  /*                                     */
  let splitted;

  if (typeof url === "string") {
    /*                                         */
    if (url.indexOf("#") > 1) {
      splitted = url.split("#");
      source = coreDeserialize(splitted[1], false);
      /*                 */
      merged = serialize(coreExtend(source, target));

      return splitted[0] + merged;
    }
    return url + fragm;
  }

  source = coreDeserialize(window.location.hash.substring(1), false);
  merged = serialize(coreExtend(source, target)) as string;
  set(merged);

  return merged;
}

assignNamespace(o_util, "fragment", {
  deserialize,
  get,
  push,
  remove,
  serialize,
  set,
});
