import { sendLog, logger } from "../../exports/debug.js";

export const log = logger("assets.global-resources.utils");

/*                                                         */
export function deprecated<F extends string, M extends string>(funcId: F, message = "" as M): void {
  const { stack } = Error("");
  log.warn(`${funcId} is deprecated, ${message}.`);
  sendLog("assets-legacy", {
    message: `${funcId}: ${message}`,
    stack,
    caller: stack?.split("\n")[2],
  });
}

export function random(): number {
  return Math.random();
}
