import { deprecated } from "../utils/tools";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
/*                                                          */
export function bind<T, F extends (this: T, ...args: any[]) => any>(
  fnThis: T,
  fn: F,
): (...args: Parameters<F>) => ReturnType<F> {
  deprecated("o_util.core.bind", "use native bind instead");

  /*                                             */
  return (...args) => fn.apply(fnThis, args);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
/*                                                    */
export function convertStringToFunction<T extends Function>(
  functionName: string,
  /*                                                          */
  context: Record<string, any>,
): T {
  const namespaces = functionName.split(".");
  const func = namespaces.pop() as string;

  let part = context;
  for (let i = 0, len = namespaces.length; i < len; i += 1) {
    part = part[namespaces[i]];
  }

  return part[func];
}
