export function dateNow(): Date {
  return new Date();
}

export function getTime(): number {
  return dateNow().getTime();
}

export function now(): number {
  return Date.now();
}
