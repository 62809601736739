import { random } from "../../utils/tools.js";
import { isPlainObject } from "../../core/index.js";
import { evalScript } from "../../hardcore/index.js";
import type {
  AjaxCallbackOrUndefined,
  AjaxGetScriptOptions,
  AjaxOptions,
  XMLHttpRequestProcessed,
} from "./types.js";
import { now } from "../../utils/date.js";

export function setResponseType(options: AjaxOptions, request: XMLHttpRequestProcessed): void {
  if (options.responseType) {
    /*                                                    */
    if (typeof request.responseType === "string") {
      /*                                                                       */
      try {
        request.responseType = options.responseType;
      } catch (error) {
        /*            */
      }
    }
  }
}

export function setRequestTimeout(
  options: AjaxOptions,
  request: XMLHttpRequestProcessed,
): NodeJS.Timeout | number | undefined {
  if (options.timeout) {
    /*                                                                   */
    if (typeof request.timeout === "number") {
      request.timeout = options.timeout;
    } else {
      return setTimeout(request.abort, options.timeout);
    }
  }

  return undefined;
}

export function handleRequestTimeout(
  /*                                                        */
  abortTimeout: number | NodeJS.Timeout | undefined,
): void {
  if (abortTimeout) {
    clearTimeout(abortTimeout as number);
  }
}

export function setContentType(options: AjaxOptions, request: XMLHttpRequestProcessed): void {
  if (options.contentType) {
    /*                                              */
    request.setRequestHeader("Content-Type", options.contentType);
  }
}

export function setHeaders(options: AjaxOptions, request: XMLHttpRequestProcessed): void {
  if (options.headers) {
    Object.entries(options.headers).forEach(([h, v]) => {
      if (typeof v === "string") {
        /*                                              */
        request.setRequestHeader(h, v);
      }
    });
  }
}

export function setWithCredentials(options: AjaxOptions, request: XMLHttpRequestProcessed): void {
  /*                                                                       */
  if (typeof options.withCredentials === "boolean") {
    request.withCredentials = options.withCredentials;
  }
}

export function sanitizeResponse(options: AjaxOptions, request: XMLHttpRequestProcessed): void {
  /*                                                                    */

  if (!!options.responseType && options.responseType === "json" && !request.responseType) {
    /*                      */
    /*                                                                                                        */
    /*                                 */
    if (request.responseText) {
      /*                                                       */
      request.responseJSON = JSON.parse(request.responseText) as never;
    }
  } else {
    request.responseJSON = request.response;
  }
}

export function handleDone(
  handler: (request: XMLHttpRequest, ev: Event) => void,
): (this: XMLHttpRequest, ev: Event) => void {
  return function doneHandler(this: XMLHttpRequest, ev: Event) {
    if (this.readyState === XMLHttpRequest.DONE) {
      handler(this, ev);
    }
  };
}

/**
 *
 *
 *
 *
 *
 */
export function validateOptions(options: AjaxOptions): Error | null {
  if (!options) {
    return new Error("Parameter 'options' is missing.");
  }

  if (!options.method) {
    return new Error("Parameter 'options.method' is missing.");
  }

  if (!options.url) {
    return new Error("Parameter 'options.url' is missing.");
  }

  if (options.headers && !isPlainObject(options.headers)) {
    return new Error("Parameter 'options.headers' must be a key/value object if set");
  }

  return null;
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function injectScript<Callback extends AjaxCallbackOrUndefined>(
  xhr: XMLHttpRequest,
  executeImmediately: boolean,
  callback?: Callback,
): XMLHttpRequest {
  let script;
  /*                                                                      */
  if ((xhr.status === 200 || xhr.status === 304) && executeImmediately !== false) {
    script = document.createElement("script");
    script.type = "text/javascript";
    script.text = xhr.responseText;

    evalScript(script);
  }

  if (typeof callback === "function") {
    callback(xhr);
  }

  return xhr;
}

/**
 *
 *
 *
 *
 *
 */
export function getCustomTimeStamp(): string {
  return now().toString() + Math.floor(random() * 999).toString();
}

/**
 *
 *
 *
 */
export function sanitizeGetScriptOptions(
  url: string,
  options?: boolean | AjaxGetScriptOptions,
): Required<AjaxGetScriptOptions & { url: string }> {
  const opts = { executeImmediately: true, cacheBusting: true, url };

  if (typeof options === "boolean") {
    opts.executeImmediately = options;
  } else if (typeof options === "object") {
    if (typeof options.executeImmediately !== "undefined") {
      opts.executeImmediately = options.executeImmediately;
    }
    if (typeof options.cacheBusting !== "undefined") {
      opts.cacheBusting = options.cacheBusting;
    }
  }

  /*                                                 */
  if (opts.cacheBusting) {
    opts.url =
      url.indexOf("?") !== -1
        ? `${url}&_=${getCustomTimeStamp()}`
        : `${url}?_=${getCustomTimeStamp()}`;
  }
  return opts;
}

export function sanitizePostOptions(data: string | Record<string, unknown>): {
  contentType: string;
  data: string;
} {
  return typeof data === "object"
    ? {
        data: JSON.stringify(data),
        contentType: "application/json; charset=utf-8",
      }
    : {
        data,
        contentType: "application/x-www-form-urlencoded; charset=utf-8",
      };
}
