/*                                                   */
/*                          */
import { loggerScope, validateModuleId, validateTopic } from "../utils/helpers.js";
import type {
  EventCallback,
  ModuleCallbackParams,
  ModuleInitParams,
  ModuleListener,
  ModuleListenerCallback,
  QBusStore,
} from "../utils/index.js";

const log = loggerScope.scope("utils");

/**
 *
 */
export interface StoreParam {
  /**
 *
 */
  store: QBusStore;
  /**
 *
 */
  topicName: string;
  /**
 *
 */
  retain?: boolean;
}

/**
 *
 */
export interface StoreCallbackParam<D extends unknown[]> extends StoreParam {
  /**
 *
 */
  callback: EventCallback<D>;
}

/**
 *
 *
 *
 */
export interface AddSubscriptionParam<D extends unknown[]> extends StoreCallbackParam<D> {
  /**
 *
 *
 */
  singleRun?: boolean;
}

export interface PublishParam<D extends unknown[]> extends StoreParam {
  data: D;
}

export function addModuleSubscription({
  params,
  callback,
  register,
}: {
  params: ModuleInitParams;
  callback: ModuleListenerCallback;
  register: (params: ModuleCallbackParams, callback: ModuleListenerCallback) => ModuleListener;
}): ModuleListenerCallback | null {
  validateModuleId(params.moduleId);
  const ids = Array.isArray(params.dependencyIds) ? params.dependencyIds : [params.dependencyIds];
  ids.forEach((i) => validateModuleId(i));

  if (typeof callback === "function") {
    register({ moduleId: params.moduleId, dependencyIds: ids }, callback);
    return callback;
  }

  return null;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function addSubscription<D extends unknown[]>({
  store,
  topicName,
  callback,
  singleRun,
}: AddSubscriptionParam<D>): EventCallback<D> | null {
  validateTopic(topicName);

  log.info(topicName, "add new subscription with options: %o", { singleRun });
  if (typeof callback === "function") {
    const topic = store.getOrAddTopic(topicName);
    /*                                                                                          */
    const listener = topic.addListener(callback, singleRun);

    /*                                          */
    if (!store.isBusMode) {
      log.debug(topicName, "queue mode is on, dispatch from queue");
      /*                                              */
      topic.emitListenerWithQueuedData(listener);
    } else if (topic.retained) {
      log.debug(topicName, "topic is retained, emit new subscriber immediatelly");
      topic.emitListener(listener, topic.retainedMessage as D);
    }

    return listener.callback;
  }

  return null;
}

export function removeSubscription<D extends unknown[]>({
  store,
  topicName,
  callback,
}: StoreCallbackParam<D>): boolean {
  log.scope(topicName).info("remove subscription", callback?.name);
  const event = store.get(topicName);
  return event ? event.removeByCallback(callback) : false;
}

export function publish<D extends unknown[]>({
  store,
  topicName,
  data,
  retain,
}: PublishParam<D>): Promise<any[]> {
  validateTopic(topicName);
  const tlog = log.scope(topicName);
  tlog.debug("emit with:", { payload: data });
  tlog.trace("emit with:", { stack: Error("").stack });

  const topic = store.getOrAddTopic(topicName);
  if (!store.isBusMode) {
    /*                                               */
    topic.queueData(data);
  }

  if (retain === true || (topic.retained && retain !== false)) {
    topic.retained = true;
    topic.retainedMessage = data;
  } else if (topic.retained) {
    topic.retained = false;
    topic.retainedMessage = undefined;
  }

  /*                                      */
  return topic.emitAllListener(data);
}
